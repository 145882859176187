#dashboard-container {
  display: flex;
}

.sidebar {
  flex: 1;
  height: 100vh;
  display: flex;
}

.panel-with-sidebar {
  flex: 7;
  padding-top: 5px;
}

.panel-without-sidebar {
  height: 100%;
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
}

#sidebar-button {
  flex: 1;
}

#sidebar-arrow {
  height: 0;
  width: 0;
  border: 10px solid transparent;
  border-right: 0;
  border-left: 10px solid $steelblue-lighten-4;
  margin-top: 10px;
}

.sidebar-nav {
  flex: 10;
  background-color: $steelblue-lighten-4;
  padding: 10px 20px;

  .nav-item {
    margin: 5px 0;

    a {
      text-decoration: none;

      span {
        margin-left: 5px;
      }
    }
  }
}

#add-vehicle-modal,
#edit-vehicle-modal {
  .modal-content {
    transform: translateY(25vh);
  }

  .input-group {
    margin: 10px 0;
  }

  .input-group > .form-control {
    flex: 3;
  }

  .input-group-text {
    flex: 1;
  }
}

#appointment-date-picker {
  flex: 3;
}

.modal-select-input {
  flex: 3;
}

.modal-select-input > div {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#add-trip-modal,
#time-keeper {
  .modal-content {
    transform: translateY(20vh);
  }

  .input-group {
    margin: 10px 0;
  }

  .input-group > .form-control {
    flex: 3;
  }

  .input-group-text {
    flex: 1;
  }
}

#set-location-modal {
  max-width: 50%;
  .modal-content {
    transform: translateY(20vh);
  }

  .input-group {
    margin: 10px 0;
  }

  .input-group > .form-control {
    flex: 3;
  }

  .input-group-text {
    flex: 1;
  }
}

#trip-admin-page-container {
  margin: 10px 20px 20px 10px;
  border: 1px solid #333;
  box-shadow: 7px 7px 5px -5px black;
  height: 80%;
}

#users-page-container {
  margin: 10px 20px 20px 10px;
  border: 1px solid #333;
  box-shadow: 7px 7px 5px -5px black;
  height: 80%;
}

#vehicle-page-container {
  margin: 10px 20px 20px 10px;
  border: 1px solid #333;
  box-shadow: 7px 7px 5px -5px black;
  height: 80%;

  #vehicle-table {
    width: 100%;
  }

  #vehicle-form {
    margin: 10px;
    padding: 5px;
    border: 1px solid #888;
    border-radius: 3px;
    display: flex;
    flex-direction: row;

    .input-group {
      margin-right: 5px;
      flex: 1;

      .input-group-text {
        font-size: smaller;
      }
    }
  }
}

#map {
  height: 45vh;
}

.pac-container {
  background-color: #fff;
  z-index: 9999;
  position: fixed;
  display: inline-block;
  float: left;
  width: 100%;
}

.modal {
  z-index: 20;
}

.modal-backdrop {
  z-index: 10;
}

.indent {
  padding-left: 5%;
}
