#top-navigation {
  position: fixed;
  height: 80px;
  width: 100%;
  top: 0;
  padding: 0.5rem 2rem;
  background-color: $light-fill;
  color: #040404;
  z-index: 999999;
}

nav {
  height: 7vh;
  min-height: 75px;
  background-color: $light-fill;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;

  ul {
    display: inline-flex;
    list-style: none;

    li {
      border-radius: 5px;
      background-color: transparent;

      a {
        margin: 5px;
        padding: 15px;
        border-radius: 5px;
      }

      a:visited,
      a:link {
        font-style: normal;
        text-decoration: none;
        color: #040404;
      }

      a:hover {
        font-style: normal;
        text-decoration: none;
        color: $light-fill;
        background-color: $steelblue-darken-2;
      }
    }
  }
}

#login-button {
  margin: 10px 0 0 0;
}

.navigation-bar {
  position: fixed;
  border-bottom: 1px solid $light-border;
  z-index: 9999;
}

.nav-section {
  flex: 1;

  ol,
  ul,
  dl {
    padding: 0;
    margin: 0;
  }
}

.nav-menu {
  flex: 7;
  text-align: right;
}

.nav-brand {
  cursor: pointer;

  img {
    margin-right: 10px;
  }

  span {
    font-weight: bold;
  }
}

.nav-authstatus {
  justify-content: right;
  display: flex;
}

.nav-wrapper {
  display: flex;
  align-items: baseline;
  width: 100%;
}

.nav-profile-button {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

@import "./responsive/740/topNavigation";
