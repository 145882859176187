@media screen and (max-width: 415px) {
  #top-panel {
    height: 300px;

    #catch-phrase {
      width: 95%;
      margin: 0 auto;
      text-align: center;
      flex: 1;

      span {
        font-size: 1.25em;
        font-weight: bold;
      }
    }

    #summary {
      flex: 3;
      font-size: 0.75em;
      font-weight: bold;
      width: 75%;
      text-align: center;
      margin: 0 auto;
    }

    #quick-search-wrapper {
      flex: 2;

      #quick-search {
        height: 70px;
        width: 95%;
        margin: 0 auto;
        background-color: $light-fill;
        position: relative;
        top: 15%;

        h5 {
          font-size: 0.75em;
        }

        .search-control {
          font-size: 0.75em;
        }

        #search-form {
          padding: 0 3px;

          #origin {
            flex: 3;
            margin: 0 5px;
          }

          #type {
            flex: 2;
            margin: 0 5px;
            height: 30px;

            option {
              font-size: 0.75em;
            }
          }

          #search-button {
            flex: 2;
            margin: 0;
            margin-top: -23px;

            span {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  #bottom-wrapper {
    #bottom-panel {
      width: 75%;
      max-width: 740px;
      margin: 0 auto;

      .row {
        margin-bottom: 30px;
      }

      #advantages-panel {
        flex-direction: column;

        #get-started {
          text-align: center;
          margin-top: 50px;

          #get-started-button {
            margin: 10px;
            background-color: $steelblue-darken-3;
          }
        }

        .advantages-icons {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
}
