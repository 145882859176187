#root {
  display: block;
  height: 100vh;
  width: 100vw;
}

#app {
  display: block;
  width: 100%;
  height: 100%;
}
