html {
  font-family: Arial, Helvetica, sans-serif;
}

.flex-element {
  display: flex;
}

.element-flex-1 {
  flex: 1;
}

.element-flex-2 {
  flex: 2;
}

.element-flex-3 {
  flex: 3;
}

.element-flex-5 {
  flex: 5;
}

.padded-vertical-5 {
  padding: 5px 0;
}

.bold-heading {
  font-size: 5em;
  font-weight: 900;
  text-shadow: 0 0 10px whitesmoke;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #f5f5f5, $alpha: 0.75);
}

.payment-container {
  height: 100vh;
  width: 100%;
}

.payment-content {
  height: 100%;
  width: 25%;
  margin: 0 auto;
  padding-top: 20%;
  align-items: center;
  align-content: center;

  label {
    width: 100%;
    text-align: center;
    background-color: red;
    color: whitesmoke;
    padding: 15px;
    border-radius: 15px;
    font-size: larger;
    font-weight: bolder;
  }
}
