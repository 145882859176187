@media screen and (max-width: 741px) {
  .payment-content {
    width: 80%;
    margin: 0 auto;

    label {
      padding: 5px;
      border-radius: 10px;
      font-size: medium;
      font-weight: bolder;
    }
  }
}
