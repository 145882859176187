#contact-wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 10vh 0;
}

#contact-container {
  padding: 25px 0 0 50px;

  ul {
    list-style: none;

    li {
      margin: 10px 0;

      a {
        text-decoration: none;
      }
    }
  }
}

#contact-facebook-icon > path {
  color: $steelblue;
}

#contact-whatsapp-icon > path {
  color: green;
}

#contact-email-icon > path {
  color: blue;
}
