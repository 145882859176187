.footer-section {
  flex: 1;
  padding: 50px 20px 20px 50px;
}

#footer-wrapper {
  position: fixed;
  display: flex;
  height: 30vh;
  width: 100%;
  bottom: -30vh;
  background-color: $steelblue-darken-1;

  h5 {
    color: $steelblue-lighten-3;
    font-weight: 600;
  }

  .nav-item > a,
  .nav-item > a:hover,
  .nav-item > a:visited {
    text-decoration: none;
    color: $steelblue-lighten-3;
  }

  @include transition(1s, cubic-bezier(0.5, 0, 0.5, 1));
}

#footer-content {
  width: 100%;
  display: flex;
}

#footer-wrapper:hover {
  bottom: 0;
}

#footer-toggle {
  position: relative;
  width: 0;
  height: 30px;
  margin: 0 auto;
  top: -30px;
  left: calc(50% - 15px);
  background-color: transparent;

  #up-arrow {
    width: 0;
    height: 0;
    border: 30px solid transparent;
    border-top: 0;
    border-bottom: 30px solid $steelblue-darken-1;
  }
}

@import "./responsive/740/siteFooter";
