#base-layout-container {
  height: 100%;
}

#base-layout-content {
  height: 100%;
  padding-top: 75px;
  background-color: $cadetblue-lighten-5;
  position: static;
}
