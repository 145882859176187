#home-page {
  height: 100vh;
  width: 100%;
  position: fixed;
  background-color: $light-fill;
  overflow-y: scroll;
}

#home-summary {
  padding: 10% 15% 5% 15%;
}

#top-panel {
  background-image: url("../images/Truck-background.jpg");
  background-size: cover !important;
  background-attachment: scroll;
  background-position: center;
  height: 450px;
  display: flex;
  padding: 20px 0 0 0;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;

  #catch-phrase {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    flex: 1;

    span {
      font-size: 3em;
      font-weight: bold;
    }
  }

  #summary {
    flex: 3;
    font-size: 1.5em;
    font-weight: bold;
    width: 30%;
    text-align: center;
    margin: 0 auto;
  }

  #quick-search-wrapper {
    flex: 2;

    #quick-search {
      height: 75px;
      min-width: 300px;
      width: 40%;
      margin: 0 auto;
      background-color: $light-fill;
      position: relative;
      top: 25%;

      h5 {
        font-size: 1em;
        color: #8a8a8a;
        padding-top: 5px;
        margin-left: 15px;
      }

      #search-form {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 0 5px;

        #origin {
          flex: 3;
          margin: 0 5px;
        }

        #type {
          flex: 2;
          margin: 0 5px;
        }

        #search-button {
          flex: 1;
          margin: 0;
          margin-top: -23px;

          span {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

#bottom-wrapper {
  width: 100%;
  margin-top: 20px;

  .row {
    margin-bottom: 50px;
  }

  #bottom-panel {
    width: 90%;
    max-width: 900px;
    margin: 0 auto;

    #advantages-panel {
      position: relative;
      height: 100%;
      width: 100%;

      #get-started {
        text-align: center;
        overflow: hidden;

        #get-started-button {
          margin: 10px;
          background-color: $steelblue-darken-3;
        }
      }

      .advantages-icons {
        height: 50px;
        width: 50px;
      }
    }
  }
}
